import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from 'src/app/services/config/configuration.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

    vibration: boolean;
    notifications: boolean;

    constructor(public configService: ConfigurationService,
        private themeService: ThemeServiceService,
        private notificationService: NotificationService) {
    }

    async ngOnInit() {

        console.log(this.configService.notifications)

        this.notificationService.notificationStatus$.subscribe((status) => {
            console.log('Estado de notificación cambiado:', status);
            console.log(status)
            // Realizar acciones según el estado de notificación (aceptada o denegada)
            if(status == "denied" || status=="granted")
            {
                if(status== "denied")
                {
                    this.configService.ChangeNotifications(false);
                }
                else{
                    this.configService.ChangeNotifications(true);
                }
            }
          });

    }

    handleChange(event: any) {

        let toggle = event.target.name;

        let value = event.detail.checked
        this.configService.setNotifications(value);
        this.notificationService.updatePermissions()


    }

     // Método para obtener el valor de DarkTheme del servicio
  public  esdark(): boolean {
    return this.themeService.isDarkTheme();
  }


}
