import {Injectable} from '@angular/core';
import {OrderService} from '../order/order.service';
import {LoadNotificacionService} from '../components/load-notificacion.service';
import {ApiService} from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    pendingCalls: { type: TypeCall, params: any }[] = []

    constructor(private _orderService: OrderService, private _loaderService: LoadNotificacionService, private apiService: ApiService) {
    }

    startService() {
        setInterval(valuesa => {
            this.realizeCalls();
        }, 30 * 1000)
    }

    public async realizeCalls(): Promise<any> {
        if (this.pendingCalls.length > 0) {
            let deleteItems = [];
            for (const item of this.pendingCalls) {
                try {

                    switch (item.type) {
                        case TypeCall.DELETE:
                            await this.apiService.deleteOrder(item.params.truck, item.params.order).toPromise().then(it => {
                                deleteItems.push(item)
                            }, it => {
                            })
                            break
                        case TypeCall.NEXTDAY:
                            await this.apiService.nextDayOrder(item.params.truck, item.params.order).toPromise().then(it => {
                                deleteItems.push(item)

                            }, error => {
                            })
                            break
                        case TypeCall.REORDER:
                            await this.apiService.moverOrders(item.params.list).toPromise().then(data => {
                                    deleteItems.push(item)

                                }
                                , error => {
                                })
                            break
                        case TypeCall.SUBMIT:
                            await this.apiService.markOrder(item.params.truck, item.params.order, item.params.type).toPromise().then(it => {
                                deleteItems.push(item)

                                ;
                            }, it => {
                            })
                            break
                        case TypeCall.REST:
                            await this.apiService.rest(item.params.truck, item.params.status).toPromise().then(it => {
                                deleteItems.push(item)

                            }, it => {
                            })
                            break

                    }
                } catch (e) {
                    console.log(e)
                }
            }
            this.pendingCalls = this.pendingCalls.filter(it => {
                return deleteItems.indexOf(it) === -1
            })

            return true

        }
    }

    saveCall(type: TypeCall, object: any) {
        let index = -1;
        switch (type) {
            case TypeCall.DELETE:
                this.pendingCalls.push({type: TypeCall.DELETE, params: object})
                break
            case TypeCall.NEXTDAY:
                this.pendingCalls.push({type: TypeCall.NEXTDAY, params: object})
                break
            case TypeCall.REORDER:
                index = this.pendingCalls.findIndex(it => it.type === TypeCall.REORDER);
                if (index !== -1)
                    this.pendingCalls[index] = {type: TypeCall.REORDER, params: object}
                else
                    this.pendingCalls.push({type: TypeCall.REORDER, params: object})
                break
            case TypeCall.REST:
                index = this.pendingCalls.findIndex(it => it.type === TypeCall.REST);
                if (index !== -1)
                    this.pendingCalls[index] = {type: TypeCall.REST, params: object}
                else
                    this.pendingCalls.push({type: TypeCall.REST, params: object})
                break
            case TypeCall.SUBMIT:
                index = this.pendingCalls.findIndex(it => it.type === TypeCall.SUBMIT && it.params.order === object.order);
                if (index !== -1)
                    this.pendingCalls[index] = {type: TypeCall.SUBMIT, params: object}
                else
                    this.pendingCalls.push({type: TypeCall.SUBMIT, params: object})
                break
        }
    }
}

export enum TypeCall {
    REORDER = 1,
    NEXTDAY = 2,
    DELETE = 3,
    SUBMIT = 4,
    REST = 5
}
