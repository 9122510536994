/* eslint-disable max-len */
import {Injectable} from '@angular/core';
import {RestService} from '../rest/rest.service';
import {RequestBase} from '../../interfaces/rest/request-base';
import {Url} from '../../interfaces/rest/url';
import {UserResponse} from '../../interfaces/user-response';
import {Company} from '../../interfaces/company.interface';
import Order from '../../interfaces/order.interface';
import {OrderResponse} from '../../interfaces/order-response';
import {LocationResponse} from '../../interfaces/location-response';
import Time from '../../interfaces/time.interface';
import {OrdersResponse} from '../../interfaces/orders-response';
import Truck from '../../interfaces/truck.interface';
import {ListResponse} from '../../interfaces/rest/list-response';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private restService: RestService,
        private http: HttpClient
    ) {
    }

    login(user: string, password: string, lang?: string) {
        const request = new RequestBase<UserResponse>(new Url('user/login', true), 'POST', this.restService);
        request.isAnonymous = true;
        request.addRequestValue('_username', user);
        request.addRequestValue('_password', password);
        request.addRequestValue('language', lang);
        request.addRequestValue('expiration', 31536000);
        return request.execute();
    }

    register(name: string, user: string, password: string) {
        const request = new RequestBase(new Url('user/register', true), 'POST', this.restService);
        request.isAnonymous = true;
        request.addRequestValue('user__email', user);
        request.addRequestValue('user__password', password);
        request.addRequestValue('user__name', name);
        return request.execute();
    }

    registerPushToken(token: string, os: number) {
        const request = new RequestBase(new Url(`user/set_token_push`, true), 'PUT', this.restService);
        request.addRequestValue('token_push', token);
        request.addRequestValue('os', os);
        return request.execute();
    }


    changePassword(oldPass: string, newPass: string) {
        const request = new RequestBase(new Url('user/change_password', true), 'PUT', this.restService);
        request.addRequestValue('old_password', oldPass);
        request.addRequestValue('new_password', newPass);
        return request.execute();
    }

    lostPassword(email: string) {
        const request = new RequestBase(new Url('user/lost_password_generator', true), 'POST', this.restService);
        request.isAnonymous = true;
        request.addRequestValue('email', email);
        return request.execute();
    }

    getCompanies() {
        const request = new RequestBase<ListResponse<Company>>(new Url('company/public_list', true), 'GET', this.restService);
        return request.execute();
    }

    findOrder(order: string) {
        if (!order) {
            return of({ data: null });
        }
        const request = new RequestBase<any>(new Url('order/find_order', true, null, {
            order_code: order
        }), 'GET', this.restService);
        return request.execute().pipe(
            catchError(error => {
                console.error('Error in findOrder:', error);
                return of({ data: null });
            })
        );
    }

    getAllOrders(order: number, truck: number) {
        const request = new RequestBase<OrdersResponse>(new Url('order/find_all_orders', true), 'GET', this.restService);
        request.addRequestValue('id_order', order);
        request.addRequestValue('id_truck', truck);
        return request.execute();
    }

    getAllOrdersTruck(truck: string) {
        const request = new RequestBase<OrdersResponse>(new Url('order/find_all_orders_driver', true), 'GET', this.restService);
        request.addRequestValue('id_truck', truck);
        return request.execute();
    }

    checkRequest(order: number) {
        const request = new RequestBase(new Url('request/create_request', true), 'POST', this.restService);
        request.addRequestValue('id_order', order);
        return request.execute();
    }

    getTruckCords(id: number) {
        const request = new RequestBase<LocationResponse>(new Url('truck/' + id + '/location', true), 'GET', this.restService);
        return request.execute();
    }

    getTimeOrder(id: number, id_truck: number) {
        const request = new RequestBase<Time>(new Url('order/' + id + '/time', true), 'GET', this.restService);
        request.addRequestValue('id_truck', id_truck);
        return request.execute();
    }

    logout(token: string, os: number) {
        const request = new RequestBase(new Url(`user/logout`, true), 'PUT', this.restService);
        request.addRequestValue('token_push', token);
        request.addRequestValue('os', os);
        return request.execute();
    }

    markOrder(truck: number, order, type) {
        const request = new RequestBase<OrdersResponse>(new Url('order/change_type', true), 'PUT', this.restService);
        request.addRequestValue('id_truck', truck);
        request.addRequestValue('id_order', order);
        request.addRequestValue('type', type);
        return request.execute();
    }

    nextDayOrder(truck: number, order) {
        const request = new RequestBase<OrdersResponse>(new Url('order/change_type', true), 'PUT', this.restService);
        request.addRequestValue('id_truck', truck);
        request.addRequestValue('id_order', order);
        request.addRequestValue('type', 2);
        return request.execute();
    }

    rest(truck: number, status) {
        const request = new RequestBase<OrdersResponse>(new Url('truck/rest', true), 'PUT', this.restService);
        request.addRequestValue('id_truck', truck);
        request.addRequestValue('status', status);
        return request.execute();
    }

    deleteOrder(truck: number, order) {
        const request = new RequestBase<OrdersResponse>(new Url('order/change_type', true), 'PUT', this.restService);
        request.addRequestValue('id_truck', truck);
        request.addRequestValue('id_order', order);
        request.addRequestValue('type', 3);
        return request.execute();
    }

    moverOrders(orders: Order[]) {
        const request = new RequestBase<OrdersResponse>(new Url('order/move', true), 'PUT', this.restService);
        request.addRequestValue('orders', JSON.stringify(orders));
        return request.execute();
    }
    checkPushOrders(truck: number) {
        const request = new RequestBase<OrdersResponse>(new Url('order/check_push', true), 'GET', this.restService);
        request.addRequestValue('id_truck',truck);
        return request.execute();
    }
    updateTruckLocation(truck: Truck, location?: string) {
        const request = new RequestBase<any>(new Url('truck/' + truck.id + '/update_location', true), 'PUT', this.restService);
        request.addRequestValue('location', location);
        return request.execute();
    }

    lastDayCheckLogin(id: number) {
        const request = new RequestBase<any>(new Url('truck/' + id + '/check_time', true), 'GET', this.restService);
        return request.execute();
    }

    contact(email: string, subject: any, message: any) {
        const request = new RequestBase<any>(new Url('user/contact', true), 'POST', this.restService);
        request.addRequestValue('email', email);
        request.addRequestValue('subject', subject);
        request.addRequestValue('message', message);
        return request.execute();
    }

  deleteAccount() {
    const request = new RequestBase(new Url('user/delete_account', true), 'PUT', this.restService);
    return request.execute();
  }

   saveCompanyUser(user: number, company: number) {
    // const registerUser = await this.http.get<any>(`${environment.v2}user/registerUser/${user}/${company}`);
    // return registerUser;
    const request = new RequestBase<OrdersResponse>(new Url(`${environment.v2}users/registerUser/${user}/${company}`, false), 'GET', this.restService);
    return request.execute();
  }
}
