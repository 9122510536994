import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/components/alert.service';
import { StorageImageService } from 'src/app/services/storageimage/storage-image.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
  selector: 'app-change-image',
  templateUrl: './change-image.component.html',
  styleUrls: ['./change-image.component.scss'],
})
export class ChangeImageComponent implements OnInit {

  constructor(private storageImageS: StorageImageService,
    private _alertService: AlertService,
    private themeService: ThemeServiceService) { }

  selectedImage: File | undefined;
  selectedImageBase64: string | undefined;

  ngOnInit() {}

  onFileSelected(event: any): void {
    const input = event.target;
    if (input.files && input.files.length > 0) {
      this.selectedImage = input.files[0];

      // Convierte la imagen a base64 para mostrar el nombre
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImageBase64 = e.target.result;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }

  public async StorageButton()
  {
    // Guarda la imagen en caché
    if(this.selectedImage)
    {
     
      await this.storageImageS.cacheImage('profileImage', this.selectedImage);
      this.storageImageS.ImageEmmitChange(this.selectedImage);
      this._alertService.presentAlertGreatSmall( 'CHANGEIMAGE.guardado');
    }
    else
    {
      this._alertService.presentAlertError('CHANGEIMAGE.seleccion');
    }
   
  }

   // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
    return this.themeService.isDarkTheme();
}
}
