import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LoadNotificacionService {


    isLoading: boolean = false;
    loading;

    constructor(private _loadingCtrl: LoadingController, private _translateService: TranslateService) {
    }


    async alertLoader(message: string) {
        console.log('llega1')
        if (this.isLoading) {
            await this._loadingCtrl.dismiss();
            this.isLoading = false;
        }
        this.isLoading = true;
        this.loading = await this._loadingCtrl.create({
            showBackdrop: true,
            spinner: 'bubbles',
            message: this._translateService.instant(message),
            duration: 10000

        });
        await this.loading.present();
    }

    async stopAlert() {
        if (this.isLoading) {
            console.log('llega2')
            await this._loadingCtrl.dismiss();
            this.isLoading = false;
        }

    }
}
