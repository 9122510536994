import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import User from 'src/app/interfaces/user.interface';
import {OrderService} from 'src/app/services/order/order.service';
import {MenuController, ToastController} from '@ionic/angular';
import {AuthService} from 'src/app/services/auth/auth.service';
import {LoadNotificacionService} from '../../../services/components/load-notificacion.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import Order from '../../../interfaces/order.interface';
import {ApiService} from '../../../services/api/api.service';
import { SearchService } from 'src/app/services/search/search.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';
import { MidnightService } from 'src/app/services/midnight-service/midnight-service.service';
import { Subject, Subscription, interval, switchMap, takeUntil, filter, catchError, of } from 'rxjs';

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, OnDestroy {
    user: User;
    searchTerm: Order;
    private destroy$ = new Subject<void>();
    orderListSubscription: Subscription;
    private lastUpdateTime: number = 0;
    private readonly UPDATE_INTERVAL = 10000; // 10 segundos
    private isUpdating = false;
    private updateSubscription: Subscription;

    constructor(
        private _orderService: OrderService,
        private _apiService: ApiService,
        private authService: AuthService,
        public localStorageService: LocalStorageService,
        private menuCtrl: MenuController,
        private translationService: TranslateService,
        private toastController: ToastController,
        private _loaderService: LoadNotificacionService,
        private searchService: SearchService,
        private themeService: ThemeServiceService,
        private midnightService: MidnightService,
        private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        try {
            this.user = this.authService.getUser();
            
            // Suscripción a cambios en la lista de órdenes
            this.orderListSubscription = this.localStorageService.getOrderListObservable()
                .pipe(takeUntil(this.destroy$))
                .subscribe((newOrderList) => {
                    if (newOrderList) {
                        this.localStorageService.saveOrderList(newOrderList);
                        this.localStorageService.check = true;
                    }
                });

            // Suscripción a cambios en el término de búsqueda
            this.searchService.searchTerm$
                .pipe(takeUntil(this.destroy$))
                .subscribe((searchTerm) => {
                    if (searchTerm) {
                        this.searchTerm = searchTerm;
                        this.updateOrderList(searchTerm);
                    }
                });

            this.midnightService.checkIf24HoursPassed();

            // Configurar el intervalo de actualización
            if (this.searchTerm) {
                this.updateSubscription = interval(this.UPDATE_INTERVAL)
                    .pipe(
                        takeUntil(this.destroy$),
                        filter(() => !this.isUpdating && this.searchTerm !== null),
                        switchMap(() => this.updateOrderList(this.searchTerm))
                    )
                    .subscribe();
            }
            
            const time = localStorage.getItem('firstTime');
            if(time == null) {
                localStorage.setItem('firstTime', '1');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error in ngOnInit:', error);
        }
    }

    private updateOrderList(searchTerm: Order) {
        if (this.isUpdating || !searchTerm) return of(null);
        
        this.isUpdating = true;
        const currentTime = Date.now();
        
        // Evitar actualizaciones muy frecuentes
        if (currentTime - this.lastUpdateTime < this.UPDATE_INTERVAL) {
            this.isUpdating = false;
            return of(null);
        }

        return this._apiService.getAllOrders(searchTerm.id, searchTerm.id_truck).pipe(
            catchError(error => {
                console.error('Error updating order list:', error);
                if (error.status === 404) {
                    this.localStorageService.saveOrderList([]);
                }
                return of(null);
            }),
            switchMap(response => {
                if (!response || !response.data || !response.data.orders) {
                    console.error('Invalid response format');
                    return of(null);
                }

                const filteredOrders = response.data.orders.filter(order => order.status === 0);
                const index = filteredOrders.findIndex(order => order.id === searchTerm.id);
                
                if (filteredOrders.length > 0) {
                    filteredOrders.forEach(order => {
                        if (order.city) {
                            order.city = order.city.charAt(0).toUpperCase() + order.city.slice(1);
                        }
                    });
                }

                if (!this.localStorageService.check) {
                    this.localStorageService.saveOrderList(filteredOrders.slice(0, index + 1));
                    this.changeDetector.detectChanges();
                }
                
                this.lastUpdateTime = currentTime;
                return of(null);
            })
        );
    }

    ngOnDestroy(): void {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
        this.user = null;
        this.localStorageService.saveOrderList(null);
        this.searchTerm = null;
        this.destroy$.next();
        this.destroy$.complete();
    }

    public esdark(): boolean {
        return this.themeService.isDarkTheme();
    }
}
