import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstallPwaService {

  deferredPrompt: any;

  constructor() { }
}
