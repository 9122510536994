import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {

    @Input() type: string;

    constructor(public language: TranslateService) {
    }

    ngOnInit() {
    }


    getLanguage() {
        return this.language.currentLang;
    }

}
