import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './guards/isAuth.guard';
import {AppRoutingPreloaderService} from './services/preloading/preloading.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'company',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'configuration',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationPageModule),
        canLoad: [AuthGuard]
    },
    {path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule), canLoad: [AuthGuard]},
    {path: 'order/:id', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule), canLoad: [AuthGuard]},
    {path: 'order', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule), canLoad: [AuthGuard]},
    // eslint-disable-next-line max-len
    {path: 'search-order', loadChildren: () => import('./pages/search-order/search-order.module').then(m => m.SearchOrderPageModule), canLoad: [AuthGuard]},
    {
        path: 'signin',
        data: {preload: true},
        loadChildren: () => import('./pages/login/signin/signin.module').then(m => m.SigninPageModule),
    },
    {
        path: ':id',
        loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyPageModule),
    },
  {
    path: 'search-order',
    loadChildren: () => import('./pages/search-order/search-order.module').then( m => m.SearchOrderPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: AppRoutingPreloaderService}),
        HttpClientModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
