import {Injectable} from '@angular/core';
import Order from '../../interfaces/order.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private orderListSubject = new BehaviorSubject<Order[]>([]);
    truck: string;
    orderList: Order[] = null;
    public check = false;

    constructor() {
    }

    public saveTruck(truck: string) {
        this.truck = truck
    }

    public saveOrderList(orderList: Order[]) {
        this.orderList = orderList;
    }

    public getOrderList(): Order[]
    {
        return this.orderList;
    }

    public deleteOrderList()
    {
        this.orderList = null;
    }

    // Método para obtener el observable de la lista de pedidos
    getOrderListObservable() 
    {
        return this.orderListSubject.asObservable();
    }

    // Método para actualizar la lista de pedidos
    updateOrderList(newOrderList: Order[]) {
        this.orderListSubject.next(newOrderList);
    }

    // Función auxiliar para verificar si el objeto tiene la forma de la interfaz Order
    public putCheckFalse() {
        this.check = false;
 }
}
