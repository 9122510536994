import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ComponentsModule} from './components/components.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {PowerManagement} from '@ionic-native/power-management/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Storage } from '@ionic/storage';
import { environment } from '../environments/environment';import { OneSignal } from 'onesignal-ngx';
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            scrollPadding: false,
            scrollAssist: false
          }),
        AppRoutingModule,
        ComponentsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
          defaultLanguage: 'es'
        }),
    ],
    providers: [
        PowerManagement,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        AppVersion,
        Network,
        Insomnia,
        Storage,
        OneSignal
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
