import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ToolbarComponent} from './shared/toolbar/toolbar.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LanguageComponent} from './modals/language/language.component';
import {NotificationsComponent} from './modals/notifications/notifications.component';
import {ChangePasswordComponent} from './modals/change-password/change-password.component';
import {AppInfoComponent} from './modals/app-info/app-info.component';
import {OrderListComponent} from './modals/order-list/order-list.component';
import {MapComponent} from './modals/map/map.component';
import {SkeletonComponent} from './shared/skeleton/skeleton.component';
import {PrivacyComponent} from './modals/privacy/privacy.component';
import {FilePath} from '@awesome-cordova-plugins/file-path/ngx';
import {DeleteAccountComponent} from "./modals/delete-account/delete-account.component";
import { TymapInstallBarComponent } from './modals/tymap-install-bar/tymap-install-bar.component';
import { ChangeImageComponent } from './modals/change-image/change-image.component';
import { ChangeThemeComponent } from './modals/change-theme/change-theme.component';

@NgModule({
  declarations: [
    ToolbarComponent,
    LanguageComponent,
    NotificationsComponent,
    ChangePasswordComponent,
    AppInfoComponent,
    OrderListComponent,
    MapComponent,
    SkeletonComponent,
    PrivacyComponent,
    DeleteAccountComponent,
    TymapInstallBarComponent,
    ChangeImageComponent,
    ChangeThemeComponent

  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    FilePath
  ],
  exports: [ToolbarComponent, SkeletonComponent,TymapInstallBarComponent,OrderListComponent, MapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
