import { Component, OnInit } from '@angular/core';
import {InstallPwaService} from '../../../services/install-pwa/install-pwa.service'
import {Storage} from '@ionic/storage';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';
import { Personalization } from 'src/app/interfaces/personalization.interface';
import { PersonalizationService } from 'src/app/services/personalization/personalization.service';

@Component({
  selector: 'app-tymap-install-bar',
  templateUrl: './tymap-install-bar.component.html',
  styleUrls: ['./tymap-install-bar.component.scss'],
})
export class TymapInstallBarComponent implements OnInit {

  constructor(private installPwaService: InstallPwaService,
    private _storage: Storage,
    private themeService: ThemeServiceService,
    private personalizationService: PersonalizationService) {}

  //public deferredPrompt: any;
  public showInstallBanner: boolean = false;
  public ButtonCancel: boolean = false;
  personalization: Personalization;

 
  public checkPersonalization() {
    this.personalization = this.personalizationService.getPersonalization();
}

  async ngOnInit(){
    this.checkPersonalization();
    
    // this.showInstallPrompt();
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      this.installPwaService.deferredPrompt = e;
      this.showInstallPrompt();
    });

    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      this.showInstallBanner = false;
      this._storage.set('InstallBanner', this.showInstallBanner);
      // Optionally, send analytics event to indicate successful install
    });
}


 private showInstallPrompt() {
    this.showInstallBanner = true;
   }
  
  public closePwaPrompt() {
    this.ButtonCancel = true;
  }
  
  public installPwa() {
    this.installPwaService.deferredPrompt.prompt();
    this.installPwaService.deferredPrompt.then((choiceResult: any) => {
      this.closePwaPrompt();
    });
  }

     // Método para obtener el valor de DarkTheme del servicio
     public  esdark(): boolean {
      return this.themeService.isDarkTheme();
      }

}
