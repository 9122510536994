import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {ApiService} from "../../../services/api/api.service";
import {LoadNotificacionService} from "../../../services/components/load-notificacion.service";
import {AlertService} from "../../../services/components/alert.service";
import {NavController} from "@ionic/angular";
import {ModalService} from "../../../services/modal/modal.service";
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {

  constructor(private _auth: AuthService,
              private apiService: ApiService,
              private _loader: LoadNotificacionService,
              private _navCtrl: NavController,
              private _modalService: ModalService,
              private _alertService: AlertService,
              private themeService: ThemeServiceService) {
  }

  ngOnInit() {
  }

  delete() {
    this._loader.alertLoader('ALERTS.DELETE-ACCOUNT.loading-message')


    this.apiService.deleteAccount().subscribe(response => {
      this._loader.stopAlert();
      this._auth.logOut();
      this._modalService.dissmissModal();
      this._alertService.presentAlertGreatSmall('ALERTS.DELETE-ACCOUNT.subHeader', 'ALERTS.DELETE-ACCOUNT.message');
    }, error => {
      console.log(error)
      this._alertService.presentAlertError('ALERTS.sever_error_title');
      this._loader.stopAlert();
    });
  }


   // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
    return this.themeService.isDarkTheme();
}
}
