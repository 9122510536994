import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageImageService {

  imageEmit$: Subject<File> = new Subject<File>();

  constructor(private storage:Storage) { }


  async cacheImage(key: string, dataUrl: File): Promise<void> {
    await this.storage.set(key, dataUrl);
  }

  async getCachedImage(key: string): Promise<File | null> {
    return this.storage.get(key);
  }

  public ImageEmmitChange(image: File)
  {
    this.imageEmit$.next(image);
  }

}
