import {Injectable, OnDestroy} from '@angular/core';
import {Platform} from '@ionic/angular';
import {OrderService} from '../order/order.service';
import {ConfigurationService} from '../config/configuration.service';
import {GeolocationService} from '../geolocation/geolocation.service';
import {Storage} from '@ionic/storage';
import { CapacitorConfig } from '@capacitor/cli';
import { OneSignal } from 'onesignal-ngx';
import { Subject, Subscription } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {


    private notificationStatusSubject = new Subject<string>();
    notificationStatus$ = this.notificationStatusSubject.asObservable();

    intervals: any[] = [];
    userId: string;
    private oneSignalInitialized = false;

    constructor(
        private platform: Platform,
        private orderService: OrderService,
        private _geo: GeolocationService,
        private storage: Storage,
        private OneSignal: OneSignal,
        private configService: ConfigurationService) {

        this.configService.configEmitter.subscribe(response => {
            console.log("Emito cambio")
            console.log(response)

            
        if (response.notifications === false) {
                this.stopListenNotifications();
            }
        })
    }

    setNotificationStatus(status: string) {
        this.notificationStatusSubject.next(status);
      }

    public async checkSubscriptionStatus() {
        //const permission = await this.OneSignal.User.PushSubscription.id;
        console.log("Paso y digo que");
        console.log(this.OneSignal.User.PushSubscription.id);
        return this.OneSignal.User.PushSubscription.id;
      }


    initConfig() {
        
        try {
            if (!this.oneSignalInitialized) 
            {
                this.OneSignal.init({
                    appId: "b3b772ad-c05f-4a59-8c69-3be625f86276",
                    // serviceWorkerParam: {
                    //     scope: "/onesignal"
                    // },
                    serviceWorkerPath: '../../../src/OneSignalSDKWorker.js'
                });
                console.log('OneSignal inicializado ok.');
                this.oneSignalInitialized = true;
            }
            else{
                console.log("OneSignal Inicializado anteriormente");
            }
        } catch (OneSignalError) {
            console.log('Error al inicializar OneSignal:')
            console.log(OneSignalError);
        }
        

        function eventListener(event) {
            console.log(`${event}`);
        }

        function DissmissListener(event)
        {
            console.log("dismissed");
        }

          
        this.OneSignal.Notifications.addEventListener("permissionChange", eventListener);
        this.OneSignal.Notifications.addEventListener("dismiss", DissmissListener);

        this.OneSignal.User.PushSubscription.addEventListener("change", (change) => {
            console.log(change);
            if (change.current.optedIn == true) {
              this.setNotificationStatus("granted");
              this.configService.initialConfig(true);
            } else {
              this.setNotificationStatus("denied");
              this.configService.initialConfig(false);
            }
          });

        if (this.OneSignal.Notifications.permissionNative == "default")
        {
             const promptOptions = {
              force: true, // Mostrar el cuadro de diálogo incluso si ya se ha mostrado antes
             };
                      
             this.OneSignal.Slidedown.promptPush(promptOptions).then((res) => {
                          console.log('Cuadro de diálogo de solicitud de permiso mostrado con éxito.', res);
                        }).catch((error) => {
                          console.error('Error al mostrar el cuadro de diálogo de solicitud de permiso:', error);
                        });
        }

       

        setInterval(() => {
            console.log(this.OneSignal.Notifications.permissionNative);
        }, 2000);
        
    }

    public updatePermissions()
    {
        console.log(this.OneSignal.Notifications.permissionNative);
        if(this.OneSignal.Notifications.permissionNative == "granted")
        {
            console.log(this.OneSignal.User.PushSubscription.optedIn)
        }
        console.log(this.OneSignal.Notifications.permissionNative);
       

    }
    
    public updateNotificationID() {
       this.userId = this.OneSignal.User.PushSubscription.id;
       console.log(this.OneSignal.User.PushSubscription.id);
    }

    public stopListenNotifications() {
        this.intervals.forEach(interval => {

            clearInterval(interval);

        });

        this.intervals = [];
    }


    setPrompt() {
      var self=this;
    }
}



