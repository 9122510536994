import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, timer } from 'rxjs';
import { switchMap, filter, startWith, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { ApiService } from '../api/api.service';
import { SidenavMenuService } from '../sidenav-menu/sidenav-menu.service';
import { LanguageService } from '../language/language.service';
import { Menu } from 'src/app/interfaces/menu.interface';
import { SearchService } from 'src/app/services/search/search.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import Order from 'src/app/interfaces/order.interface';

@Injectable({
  providedIn: 'root'
})
export class MidnightService {
  constructor(
    private _apiService: ApiService, 
    private storage: Storage,
    private _sidenavService:SidenavMenuService, 
    private _languageService:LanguageService,
    private searchService: SearchService,
    private localStorageService: LocalStorageService) {
  }

  scheduleMidnightTask() {
    const midnightTrigger$ = this.midnightTrigger();

    midnightTrigger$.subscribe(() => {
      this.clearMenuFromStorage();
      console.log("ikusi arte")
    });
  }

  saveStartOfDay() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Establecer la hora a las 00:00:00
    this.storage.set('startOfDay', currentDate);
  }

  async checkIf24HoursPassed() {
    // Obtener la fecha de inicio del día almacenada
    console.log("CHECKEO 24H")
    const startOfDay = await this.storage.get('startOfDay');
  
    if (startOfDay) {
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - new Date(startOfDay).getTime();
      const hoursDifference = timeDifference / (1000 * 3600);
  
      if (hoursDifference >= 24) {
        console.log('Han pasado más de 24 horas desde la fecha de inicio del día.');
        this.clearMenuFromStorage();
        console.log("ikusi arte")
        this.saveStartOfDay()
      } else {
        console.log('No han pasado 24 horas desde la fecha de inicio del día.');
      }
    } else {
      console.log('No hay fecha de inicio del día almacenada.');
      this.saveStartOfDay();
    }
  }

  // Función para determinar si es medianoche
  isMidnight(date: Date): boolean {
    return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
  }

  timeUntilMidnight() {
    const now = new Date();
    //console.log(now)
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    //console.log(midnight.getTime() - now.getTime())
    return midnight.getTime() - now.getTime();
  }

  private menuData: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]) ;
  public async clearMenuFromStorage() {
    this.storage.remove('menu');
    this.storage.remove('order');
    this.localStorageService.updateOrderList(null);
    var lang = await this._languageService.getCurrentLang();
    var menu = await this._sidenavService.getMenuItems( lang);
    this.localStorageService.deleteOrderList()
    menu.subscribe((data) => {
      console.log(data);
      this.menuData.next(data);
      console.log(this.menuData.value)
      this._sidenavService.emitMenuChange(this.menuData.value);
    });
    this._sidenavService.emmitOrderChange(null);
    this.searchService.setSearchTerm(<Order>null); // Para actualizar automaticamente la barra de busquedas
    const mapContainer = document.querySelector('.map-container') as HTMLElement;
    if (mapContainer) {
        mapContainer.classList.remove('map-container-dark');
    }
   
   
  }

   // Función para generar un observable que emite cuando es medianoche
   midnightTrigger(): Observable<any> {
    return timer(0, 1000).pipe( // Verifica cada segundo
      startWith(new Date()), // Inicia con la hora actual
      map(() => new Date()), // Transforma el número en objeto Date
      filter(date => this.isMidnight(date))
    );
  }
}






 


 
