import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GeolocationService} from 'src/app/services/geolocation/geolocation.service';
import {AuthService} from 'src/app/services/auth/auth.service';
import {OrderService} from 'src/app/services/order/order.service';
import {AlertService} from 'src/app/services/components/alert.service';
import {ActivatedRoute} from '@angular/router';
// @ts-ignore
import {MenuController, NavController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {NetworkService} from '../../../services/network/network.service';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import Order from '../../../interfaces/order.interface';
import {ApiService} from '../../../services/api/api.service';
import {BaseResponse} from '../../../interfaces/rest/base-response';
import {LocationResponse} from '../../../interfaces/location-response';
import {environment} from '../../../../environments/environment';
import { RestResponse } from 'src/app/interfaces/rest/rest-response';
import RequestInterface from 'src/app/interfaces/request.interface';
import { LoadNotificacionService } from 'src/app/services/components/load-notificacion.service';
import { Observable, of, takeUntil } from 'rxjs';
import { SidenavMenuService } from 'src/app/services/sidenav-menu/sidenav-menu.service';
import { SearchService } from 'src/app/services/search/search.service';
import { Company } from 'src/app/interfaces/company.interface';
import User from 'src/app/interfaces/user.interface';
import { Subscription } from 'rxjs';
import { TemporizadormapService } from 'src/app/services/temporizadormap/temporizadormap.service';

import * as L from 'leaflet';
import 'leaflet-fullscreen';
import { MidnightService } from 'src/app/services/midnight-service/midnight-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnDestroy, OnInit, AfterViewInit {


    public showTimer = false;
    order$: Observable<Order>;

    // User and driver variables
    orderNumber: string;
    truck: string;
    order: Order
    truckTracking: any;
    date: string = null;
    zoomLevel :number;

    param = {order: ''};
    truckCords={ lat: 0, lng: 0 }
    mapLeaflet: L.map;

    /* Outputs */
    ready = false;
    public user: User;

    diffDate: string | null = null;
    tiempoEnSegundos:number;
    private diffDateSubscription: Subscription;

    constructor(
        private _geolocation: GeolocationService,
        private _activatedRoute: ActivatedRoute,
        public _auth: AuthService,
        private networkService: NetworkService,
        private localStorage: LocalStorageService,
        private platform: Platform,
        private storage: Storage,
        private apiservice: ApiService,
        private menuCtrl: MenuController,
        private _navCtrl: NavController,
        private _alert: AlertService,
        public _order: OrderService,
        private _loader: LoadNotificacionService,
        private _alertService: AlertService,
        private _sideNavService: SidenavMenuService,
        private searchService: SearchService,
        private temporrizadorMap: TemporizadormapService,
        private midnightService:MidnightService,
        private themeService: ThemeServiceService,
        private changeDetector: ChangeDetectorRef) {
    }

    EnableMap()
    {
        if(this.user.company.plan === 1)
        {
            
            this.ConsumirRequest().then( () => {
                const mapContainer = document.querySelector('.map-container') as HTMLElement;
                if (mapContainer) {
                    mapContainer.classList.remove('map-container-dark');
                }

                const textRequest = document.querySelector('.h3color') as HTMLElement;
                if (textRequest) {
                    mapContainer.classList.add('h3colorTrasnparent');
                }
                this.temporrizadorMap.emitDiffDatedefault();
                this.LoadMapInstance();
            });
            this.botonenabled = false;
            setTimeout(() => {
                this.desactivarMapa();
                this.tiempoEnSegundos = 0;
                document.querySelector('.map-container').classList.add('map-container-dark');
                document.querySelector('.map-containertest').classList.remove('leaflet-container');
            }, 1000 * 60 * 5);
        }
        else{
            this._alertService.companyInactiveAlert({company: this.user.company.name});
        }
       
       
    }

    botonenabled:boolean= false;
    private searchTermSubscription: Subscription;
    ngOnInit() {
         this._sideNavService.orderEmmit$.subscribe(order => {
            this.order = order;
            this.order$ = of(this.order);
            this.date= null;
            clearInterval(this.truckTracking);// Paramos el anterior trucking de api rest
            this.changeDetector.detectChanges(); // Notificar a Angular de los cambios después de completar la operación de almacenamiento
            if(this.order!= null)
            {
                this.apiservice.findOrder(this.order.order_code).subscribe((it) => {
                
                    this._order.request = it.data.request;
                    //this._order.request_date = it.data.request_date //
                });

            }
         
        })
      
        this.user = this._auth.getUser();
        this.midnightService.checkIf24HoursPassed();
        this._sideNavService.getCompany().subscribe(
            (company: Company) => {
            // Asegúrate de que this.user y this.user.company estén definidos
            if (this.user) {
                this.user.company = company;
            }
            },
            (error) => {
              console.error('Error al obtener la compañía:', error);
            }
          );

          this.diffDateSubscription = this.temporrizadorMap
          .getDiffDateObservable()
          .subscribe((diffDate: string | null) => {
            this.diffDate = diffDate;
            if (this.diffDate !== null) {
              const tiempoArray = this.diffDate.split(' '); // Divide la cadena en un array ["X", "min", "X", "sec"]
              
              const minutos = parseInt(tiempoArray[0]); // Convierte el primer elemento a un número
              const segundos = parseInt(tiempoArray[2]); // Convierte el tercer elemento a un número
            
              this.tiempoEnSegundos = minutos * 60 + segundos;
            
              if (this.tiempoEnSegundos < 0) {
                this.desactivarMapa();
                this.tiempoEnSegundos = null;
                this.diffDate = null;
                this.temporrizadorMap.stopTracking();
                const mapContainer = document.querySelector('.map-containertest') as HTMLElement;
                if (mapContainer) {
                    mapContainer.classList.add('map-container-dark');
                }
              }
            
          }
          });

        this.searchTermSubscription =this.searchService.searchTerm$.subscribe(async (searchTerm) => {
            if(this.order)
            {
                if(searchTerm.id != this.order.id)
                {
                    this.temporrizadorMap.stopTracking();
                    this.botonenabled = true;
                    this.order = searchTerm;
                    this.order$ = of(this.order);
                    clearInterval(this.truckTracking);// Paramos el anterior trucking de api rest
                    this.mapLeaflet.remove();
                    this.mapLeaflet = null;
                    this.date = null;
                    this.diffDate = null;
                }
                else{
                    this.temporrizadorMap.emitDiffDatedefault();
                   
                }
            }
            if(searchTerm != null)
            {
                const mapContainer = document.querySelector('.map-container') as HTMLElement;
                if (mapContainer) {
                    mapContainer.classList.add('map-container-dark');
                }
                this.botonenabled = true;
                this.order = searchTerm;
                this.order$ = of(this.order);
            }
          
            
            
            this.tiempoEnSegundos = this.temporrizadorMap.getDiffDate();
            
            if(searchTerm != null
                && this.tiempoEnSegundos <0)
            {
                this.order = searchTerm; 
                this.order$ = of(this.order);
                this.botonenabled = true;
                this.date = null;
                this.diffDate = null;
                this.mapLeaflet.remove();
                this.temporrizadorMap.stopTracking();
                this.mapLeaflet = null;
                const mapContainer = document.querySelector('.leaflet-container') as HTMLElement;

                if (mapContainer) {
                    mapContainer.classList.remove('leaflet-container');
                }
            }
        });
       
        

        
         this.initData().then( () => {
            const mapContainer = document.querySelector('.map-container') as HTMLElement;
            if (mapContainer) {
                mapContainer.classList.remove('map-container-dark');
            }
            
            this.LoadMapInstance(); // Sin consumir request porque el time es > 0
         })
        
    }

    restofComponent: any = true;

     public desactivarMapa() {

        // const mapContainer = document.querySelector('.map-container') as HTMLElement;
        // if (mapContainer) {
        //     mapContainer.classList.add('map-container-dark');
        // }
        this.botonenabled = true;
        this.restofComponent = false;
        this.mapLeaflet.remove();
        this.mapLeaflet = null;
        clearInterval(this.truckTracking);
   }


    public clearApiTrucking()
    {
        clearInterval(this.truckTracking);// Paramos el anterior trucking de api rest
    }
         

    

    public async LoadMapInstance()
    {
        this.getTime();
        await this.loadMap();
        await this.trackingLeaflet()
        this.centerLeaflet("truck",12);
        await this.setMarkerLeaflet({lat: Number(this.order.location.split(';')[1].trim()), lng: Number(this.order.location.split(';')[0].trim())},false);
    }

    searchTerm:any;

    public ConsumirRequest()
    {
        return new Promise<void>((resolve, reject) => {
        this.apiservice.checkRequest(this.order.id).subscribe((it: RestResponse<RequestInterface>) => {
            this._order.request = it.data.request
            this._order.request_date = it.data.request_date
    
                if (it.status === 215 ) {
                    this._loader.stopAlert()
                    this._alertService.presentAlertError('ALERTS.EXCEEDED-ALERT.message', {
                        max_number: it.data.consults,
                        date: it.data.time
                    })
                    reject(); // Rechazar la promesa si no se debe cargar LoadMapInstance
                } else if (it.status === 216) {
                    this._loader.stopAlert()
                    this._alertService.presentAlertError('ALERTS.INFO-ALERT.delivered');
                    reject(); // Rechazar la promesa si no se debe cargar LoadMapInstance
                }
                else if (it.status == 200){
                    resolve();
                    this.botonenabled = false;
                }
               
        });
    });
}
   


    private initData() {
        this.botonenabled =false;
        return new Promise<void>((resolve, reject) => {
            this._order.savedOrder().then(async order => {
                this.order = order;
                this.order$ = of(this.order);
                if(this.order != null){
                    this.apiservice.findOrder(this.order.order_code).subscribe((it) => {
                    
                        this._order.request = it.data.request;
                        this._order.request_date = it.data.request_date
                    });
                }

                if (this.order != undefined  && this.tiempoEnSegundos <=0)  {
                        this.botonenabled = true;
                        this.date = null;
                    reject(); // Resolver la promesa si todo va bien
                } else if (this.tiempoEnSegundos > 0){
                    // Manejar el caso en el que order sea null si es necesario
                    resolve();
                }
            });
        });
    }

    @ViewChild('map', { static: false }) mapRef!: ElementRef;
    olgmInstance: any; // Cambiado de const a propiedad de la clase

    

    private async loadMap() {
        if (!this.order || !this.order.location) {
            console.error('No hay orden o ubicación disponible');
            return;
        }

        const [lat, lng] = this.order.location.split(';').map(coord => Number(coord.trim()));
        if (isNaN(lat) || isNaN(lng)) {
            console.error('Coordenadas inválidas:', this.order.location);
            return;
        }

        const mapOptions = {
            center: [lat, lng],
            fullscreenControl: true,
            zoom: 8,
            attributionControl: false,
        };

        if (this.mapLeaflet) {
            this.mapLeaflet.remove();
        }

        this.mapLeaflet = L.map('map', mapOptions);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 22
        }).addTo(this.mapLeaflet);
    }

    private currentMarker: L.Marker | null = null;  // Agrega una propiedad para mantener la referencia al marcador actual
    async setMarkerLeaflet(latlng: { lat: any, lng: any }, isTruck: boolean): Promise<void> {

    // Elimina el marcador anterior si existe
        if (this.currentMarker) {
            //Remove Marker
            this.mapLeaflet.removeLayer(this.currentMarker);
        }
        // Define la URL de la imagen según el tipo
        const initialSize = isTruck ? [23, 50] : [30, 50]; // Tamaño inicial del ícono
        const imageUrl = isTruck ? 'assets/markers/camion3.png' : 'assets/markers/puntero.png';

        if(isTruck)
        {
              // Crea un ícono personalizado
              const customIcon = L.icon({
                iconUrl: imageUrl,
                iconSize: initialSize , // Define el tamaño del ícono [width, height]
                iconAnchor: [initialSize[0] / 2, initialSize[1]], // Define el punto de anclaje del ícono [x, y]
            });
            // Añade un marcador con el ícono personalizado
            this.currentMarker = L.marker([latlng.lat, latlng.lng], { icon: customIcon }).addTo(this.mapLeaflet);

        //       // Agrega un evento para ajustar el tamaño del ícono en función del zoom
        // this.mapLeaflet.on('zoomend', () => {
        //     const currentZoom = this.mapLeaflet.getZoom();
        //     if( currentZoom >=12 ||  currentZoom <=9)
        //     {
        //         //No aplicar
        //         //No aplicar
        //     }
        //     else{
        //         const scaleFactor = 1 - (currentZoom - 10) * 0.1; // Ajusta el factor de escala según tus preferencias

        //         // Calcula el nuevo tamaño del ícono
        //         const newSize = [
        //         Math.max(initialSize[0] * scaleFactor, 10), // Mínimo tamaño del ícono
        //         Math.max(initialSize[1] * scaleFactor, 10), // Mínimo tamaño del ícono
        //         ];
    
        //         // Actualiza el tamaño del ícono
        //         customIcon.options.iconSize = newSize;
        //         customIcon.options.iconAnchor = [newSize[0] / 2, newSize[1] / 2];
    
        //         // Actualiza el ícono del marcador
        //         this.currentMarker?.setIcon(customIcon);

        //     }
           
        // });
        }
        else
        {
             // Crea un ícono personalizado
            const customIcon = L.icon({
                iconUrl: imageUrl,
                iconSize: initialSize , // Define el tamaño del ícono [width, height]
                iconAnchor: [initialSize[0] / 2, initialSize[1]], // Define el punto de anclaje del ícono [x, y]
            });

             // Añade un marcador con el ícono personalizado
            const marker = L.marker([latlng.lat, latlng.lng], { icon: customIcon }).addTo(this.mapLeaflet);

        //       // Agrega un evento para ajustar el tamaño del ícono en función del zoom
        // this.mapLeaflet.on('zoomend', () => {
        //     const latlng = this.mapLeaflet.getCenter();
        //     const currentZoom = this.mapLeaflet.getZoom();
        //     console.log(currentZoom)
        //     if( currentZoom >=12 ||  currentZoom <=9)
        //     {
              
        //         //no aplicar
        //     }
        //     else{
        //         const scaleFactor = 1 - (currentZoom - 10) * 0.1; // Ajusta el factor de escala según tus preferencias

        //         // Calcula el nuevo tamaño del ícono
        //         const newSize = [
        //         Math.max(initialSize[0] * scaleFactor, 10), // Mínimo tamaño del ícono
        //         Math.max(initialSize[1] * scaleFactor, 10), // Mínimo tamaño del ícono
        //         ];
    
        //         // Actualiza el tamaño del ícono
        //         customIcon.options.iconSize = newSize;
        //         customIcon.options.iconAnchor = [newSize[0] / 2, newSize[1] / 2];
    
        //         // Actualiza el ícono del marcador
        //         marker.setIcon(customIcon);
        //         // Actualiza la vista para centrar el mapa en la ubicación actual
        //         this.mapLeaflet.setView(latlng, currentZoom);

        //     }
           
        // });

        }

       
    }

    ngOnDestroy(): void {
        clearInterval(this.truckTracking);
        this.date = null;


    }

    public async centerLeaflet(type: string, zoom:number): Promise<void> 
    {
        const targetCoordinates = type === 'truck'
          ? [Number(this.truckCords.lat), Number(this.truckCords.lng)]
          : [Number(this.order.location.split(';')[1].trim()), Number(this.order.location.split(';')[0].trim())];
      
        this.mapLeaflet.setView(targetCoordinates, zoom);
    }


    private async trackingLeaflet() {
        if(this.tiempoEnSegundos <=0)
        {   
            await this.storage.set('mapTime', new Date().toString());
        }
        const response: BaseResponse<LocationResponse> = await this.apiservice.getTruckCords(this.order.id_truck).toPromise()
            const latlng = {lat: Number(response.data.truck.lat), lng: Number(response.data.truck.long)}
            this.truckCords=latlng;
            this.setMarkerLeaflet(latlng,true);
           
        if(this.tiempoEnSegundos <=0)
        {
            this.temporrizadorMap.ponerdestroyedFalse();
            this.temporrizadorMap.startTracking();
        }
        this.truckTracking = setInterval(async () => {
            const response: BaseResponse<LocationResponse> = await this.apiservice.getTruckCords(this.order.id_truck).toPromise()
            const latlng = {lat: Number(response.data.truck.lat), lng: Number(response.data.truck.long)}
            this.truckCords=latlng;
            this.setMarkerLeaflet(latlng, true);
        }, 1000)
    }

  


    private async getTime() {
        this.apiservice.getTimeOrder(this.order.id, this.order.id_truck).subscribe(it => {
            const h = Math.floor(it.data.time / 3600);
            const m = Math.floor(it.data.time % 3600 / 60);
            const s = Math.floor(it.data.time % 3600 % 60);
            this.date = (h + ' h ' + m + ' min ');
            this.showTimer = true;

        });
    }

      // Método para obtener el valor de DarkTheme del servicio
   public  esdark(): boolean {
    return this.themeService.isDarkTheme();
}

ngAfterViewInit() {
    // Asegurarse de que el mapa se inicialice después de que la vista esté lista
    if (this.order) {
        this.initData().then(() => {
            const mapContainer = document.querySelector('.map-container') as HTMLElement;
            if (mapContainer) {
                mapContainer.classList.remove('map-container-dark');
            }
            this.LoadMapInstance();
        });
    }
}

}
