import {ResponseStructure} from './response-structure';
import {BaseResponse} from './base-response';

export class RestResponse<T> implements BaseResponse<T> {
    readonly status: number = 200;
    readonly message: string = null;
    readonly data: T;

    constructor(responseData: ResponseStructure<T>, private path: string) {
        this.status = responseData.status || 500;
        if (typeof responseData === 'string') {
            throw new Error(path + ' Not exist');
        }
        if (!this.status) {
            throw new Error('Malformed response. Status is required');
        }
        this.message = responseData.message;
        this.data = responseData.data;

    }

    isSuccess(): boolean {
        return this.status >= 200 && this.status < 299;
    }

    isError(): boolean {
        return this.status >= 400 && this.status < 599;
    }

    shouldShowMessage(): boolean {
        return !!this.message || this.isError();
    }


    private getMessage(): string {
        if (this.message) {
            return this.message;
        }

        return 'Unexpected error ' + this.status;
    }

    /*  showMessage(): void {
          if (this.shouldShowMessage()) {
              new MessageBase(this.type, this.getMessage(), '', this.action, this.serviceHolder).show();
          }
      }*/
}
