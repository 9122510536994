import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from 'src/app/services/auth/auth.service';
import {LoadNotificacionService} from 'src/app/services/components/load-notificacion.service';
import {AlertService} from 'src/app/services/components/alert.service';
import User from 'src/app/interfaces/user.interface';
import {ApiService} from '../../../services/api/api.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {


    changeForm: FormGroup;

    private user: User
    showPassword1=false;
    showPassword2=false;
    showPassword3=false;
    @ViewChild('password1') passwordInput1: HTMLIonInputElement;
    @ViewChild('password2') passwordInput2: HTMLIonInputElement;
    @ViewChild('password3') passwordInput3: HTMLIonInputElement;

    constructor(
        private _auth: AuthService,
        private apiService: ApiService,
        private _loader: LoadNotificacionService,
        private _alertService: AlertService,
        private themeService: ThemeServiceService
    ) {
    }

    async ngOnInit() {
        this.user = await this._auth.getUser();
        this.initForm();
    }


    public save() {


        this._loader.alertLoader('ALERTS.CHANGE-PASSWORD.loading-message')


        this.apiService.changePassword(this.changeForm.get('currentPassword').value, this.changeForm.get('newPassword').value).subscribe(response => {
            this._loader.stopAlert();
            this._alertService.presentAlertGreatSmall('ALERTS.CHANGE-PASSWORD.subHeader', 'ALERTS.CHANGE-PASSWORD.message')
            this._auth.saveStorage(this.user);
            this.resetForm();
        }, error => {
            if (error.status === 400) {
                this._alertService.presentAlertError('ALERTS.CHANGE-PASSWORD.DONTMATCH.subHeader', 'ALERTS.CHANGE-PASSWORD.DONTMATCH.message')
            }
        });
    }

    private initForm() {
        this.changeForm = new FormGroup({

            currentPassword: new FormControl(null, [Validators.required]),
            newPassword: new FormControl(null, [Validators.required]),
            repeatPassword: new FormControl(null, [Validators.required]),

        }, {validators: this.matchPassword})

    }

    private resetForm() {
        this.changeForm.reset();
    }

    private matchPassword(password: FormGroup) {

        let newPass: FormControl = password.controls.newPassword.value;
        let repeatPass: FormControl = password.controls.repeatPassword.value;
        return newPass === repeatPass ? null : {notSame: true}
    }

    private isUserPassword(): boolean {

        const currentPassword = this.user.password;
        const typedPassword = this.changeForm.get('currentPassword').value;

        //console.log('actual', currentPassword);
        //console.log('typed', typedPassword);

        if (currentPassword === typedPassword) {
            return true
        }
        this._alertService.presentAlertError('ALERTS.CHANGE-PASSWORD.DONTMATCH.subHeader', 'ALERTS.CHANGE-PASSWORD.DONTMATCH.message') //TODO CHECK
        return false
    }

    toggleShow1() {
        this.showPassword1 = !this.showPassword1;
        this.passwordInput1.type = this.showPassword1 ? 'text' : 'password';
    }
    toggleShow2() {
        this.showPassword2 = !this.showPassword2;
        this.passwordInput2.type = this.showPassword2 ? 'text' : 'password';
    }
    toggleShow3() {
        this.showPassword3 = !this.showPassword3;
        this.passwordInput3.type = this.showPassword3 ? 'text' : 'password';
    }

      // Método para obtener el valor de DarkTheme del servicio
    public  esdark(): boolean {
        return this.themeService.isDarkTheme();
  }
  

isFocused1: boolean = false;
isFocused2: boolean = false;
isFocused3: boolean = false;


handleFocus1() {
    this.isFocused1 = true;
}
  

handleBlur1() {
    this.isFocused1 = false;
}


handleFocus2() {
    this.isFocused2 = true;
}
  

handleBlur2() {
    this.isFocused2 = false;
}


handleFocus3() {
  this.isFocused3 = true;
}


handleBlur3() {
  this.isFocused3 = false;
}

}
