import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private languageSubject = new BehaviorSubject<string>(this._translate.currentLang);
    language$: Observable<string> = this.languageSubject.asObservable();


    public selected: string;

    constructor(private _translate: TranslateService, private _storage: Storage) {
        this._translate.onLangChange.subscribe((event) => {
            this.languageSubject.next(event.lang);
          });
    }


    public async setInitialLanguage() {
        const language = this._translate.getBrowserLang();
        this._translate.setDefaultLang(language);
          await this.setStorageLang(language);

    }

    public setLanguage(lng: string) {
        this._translate.use(lng);
        this._storage.set('language', lng);
        this.selected = lng;
    }

    public async getCurrentLang(): Promise<string> {
        return await this._storage.get('language');
    }

    public async setStorageLang(newLang?: string): Promise<string> {
        const lang = await this._storage.get('language') || null;
        if (lang === null) {
            this.setLanguage(newLang);
        } else {
            this.setLanguage(lang);
        }

        return lang;
    }
}
