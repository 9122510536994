import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Params} from '@angular/router';
import {Observable, Observer} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {ResponseStructure} from '../../interfaces/rest/response-structure';
import {RestRequest} from '../../interfaces/rest/rest-request';
import {Storage} from '@ionic/storage';
import {timeout} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RestService {
    private globalUrlParams: Params = {};
    private _globalRequestParams: Params = {};

    constructor(private http: HttpClient, private auth: AuthService, private _storage: Storage,
                private activatedRoute: ActivatedRoute) {

    }

    addGlobalUrlParams(key: string, value: string) {
        this.globalUrlParams[key] = value;
    }

    execute<T>(request: RestRequest<T>): Observable<ResponseStructure<T>> {
        return new Observable<ResponseStructure<T>>((observer) => {
            this.doRequest(request, observer);
        });
    }

    prepareRequest<T>(request: RestRequest<T>) {
        request.url.params = {...request.url.params, ...this.activatedRoute.snapshot.params};
        request.url.addUrlParams(this.globalUrlParams);
        if (request.addRequestParams) {
            this.addCurrentRequestParams<T>(request);
        }
    }

    private doRequest<T>(request: RestRequest<T>, observer: Observer<ResponseStructure<T>>) {
        this.prepareRequest(request);
        //  this.checkCookies(request);//FUTURE COOKIES SERVICE
        const httpRequest = new HttpRequest<string>(request.type, request.url.path, request.body, {
            params: this.getHttpParams(request),
            headers: request.headers.set('X-Auth-Token', this.isTheAuthTokenNotNecessary(request) ? '' : this.auth.sessionToken)
                .set('Lang', this.auth.lang)
        });
        let finalResult: HttpResponse<ResponseStructure<T>> = null;
        const response = this.http.request<ResponseStructure<T>>(httpRequest);

        let finalError: HttpErrorResponse;

        if (finalResult !== null) {
            observer.next(finalResult.body);
        } else if (typeof finalError !== 'undefined') {
            observer.error(finalError);
        } else {
            response
                .subscribe((result: HttpEvent<ResponseStructure<T>>) => {
                    if(result !== undefined) {
                        if (result.type === HttpEventType.Response) {
                            finalResult = result as HttpResponse<ResponseStructure<T>>;
                            if (finalResult.headers.has('X-Auth-Token') && !request.transparent) {
                                this.auth.sessionToken = finalResult.headers.get('X-Auth-Token');
                            }
    
                            // console.log(finalResult);
                            observer.next(finalResult.body);
                        }

                    }
                }, (error: any) => {
                    // console.error(error);
                    if (typeof finalError === 'undefined') {
                        error.status = 500;
                        finalError = error;
                    }
                    if (request.handle401Error && finalError.error && finalError.error.status === 401) {
                        this.auth.logOut();
                        /*   window.location.reload();*/
                    }
                    observer.error(finalError.error);
                });
        }
    }

    getHttpParams<T>(request: RestRequest<T>): HttpParams {
        let httpParams = request.httpParams;

        Object.keys(this._globalRequestParams).map(key => {
            httpParams = httpParams.append(key, this._globalRequestParams[key]);
        });

        return httpParams;
    }

    private addCurrentRequestParams<T>(request: RestRequest<T>): void {
        const params = this.activatedRoute.snapshot.queryParams;
        Object.keys(params).map(key => {
            if (typeof request.getRequestValue(key) === 'undefined') {
                request.addRequestValue(key, params[key]);
            }
        });
    }

    get globalRequestParams(): Params {
        return this._globalRequestParams;
    }

    addGlobalRequestParam(name: string, value: string) {
        this._globalRequestParams[name] = value;
    }

    isTheAuthTokenNotNecessary(request): boolean {
        return request.isAnonymous;
    }
}
