import {Component, OnInit} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {TranslateService} from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';
@Component({
    selector: 'app-app-info',
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent implements OnInit {

    version: string;
    year: number = new Date().getFullYear();

    constructor(
        private _appVersion: AppVersion,
        private translate: TranslateService) {
    }

    async ngOnInit() {


        this.version = await this._appVersion.getVersionNumber();

    }

    public openView(): void {

        const urls = this.translate.currentLang === 'es' ? 'https://tymap.app/licencia.html' : 'https://tymap.app/US/licencia.html'
        const openCapacitorSite = async () => {
            await Browser.open({ url: urls });
          };
    }

}
